@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	*:focus {
		outline: none !important;
	}

	:root {
		--color-primary: 11 11 11;
		--color-secondary: 116 113 111;
		--color-tertiary: 177 175 172;
		--color-placeholder: 186 186 182;
		--color-border: 226 224 222;
		--color-background: 245 245 245;
		--color-background-secondary: 241 239 234;
		--color-card: 233 233 232;
		--color-accent: 108 93 171;
		--color-accent-secondary: 255 132 84;

		--radius: 1.25rem;

		/* Light theme syntax highlighting */
		@import "highlight.js/styles/github.css";
	}

	.dark:root {
		--color-primary: 220 220 227;
		--color-secondary: 135 135 144;
		--color-tertiary: 86 86 93;
		--color-placeholder: 73 73 77;
		--color-border: 49 49 54;
		--color-background: 24 24 27;
		--color-background-secondary: 37 37 41;
		--color-card: 38 38 42;
		--color-accent: 116 97 192;
		--color-accent-secondary: 255 132 84;

		/* Dark theme syntax highlighting */
		@import "highlight.js/styles/github-dark.css";
	}

	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-primary;
		font-feature-settings:
			"rlig" 1,
			"calt" 1;
	}

	input::placeholder,
	textarea::placeholder {
		@apply text-placeholder;
	}
}

.main-editor {
	padding-bottom: 8rem;

	> * + * {
		margin: 0.33rem 0;
	}
}

.doc-editor {
	@apply md:px-inline-8;
}

.ProseMirror:focus {
	@apply outline-none;
}

.tiptap .indent {
	padding-inline: 1rem 0;
}

.tiptap .indent-level-2 {
	padding-inline: 2rem 0;
}

.tiptap .indent-level-3 {
	padding-inline: 3rem 0;
}

.tiptap p.is-editor-empty:first-child::before {
	color: hsl(var(--muted-foreground));
	content: attr(data-placeholder);
	float: left;
	height: 0;
	pointer-events: none;
}

.tiptap :first-child {
	margin-top: 0;
}

.tiptap ul,
.tiptap ol {
	@apply space-y-1;
	padding: 0 1rem;
	margin: 0;
}

.tiptap ol {
	list-style-type: decimal;
}

.tiptap ul {
	list-style-type: disc;
}

.tiptap li p {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
}

.thought-feed-view ul,
.thought-feed-view ol {
	padding: 0 1rem;
	margin: 0;
}

.thought-feed-view ol {
	list-style-type: decimal;
}

.thought-feed-view ul {
	list-style-type: disc;
}

.thought-feed-view li p {
	margin-top: 0.25em;
	margin-bottom: 0.25em;
}

.tiptap li.editor-class-list {
	list-style-type: none;
}

.tiptap li.editor-task-item {
	@apply gap-1;
	display: flex;
	flex-direction: row;
}

.tiptap h1,
.tiptap h2,
.tiptap h3,
.tiptap h4,
.tiptap h5,
.tiptap h6 {
	line-height: 1.1;
	margin-top: 1rem;
	margin-bottom: 0.34rem;
	font-weight: 600;
	text-wrap: pretty;
}

.tiptap h1,
.tiptap h2 {
	margin-top: 1.5rem;
	margin-bottom: 0.67rem;
}

.tiptap h1 {
	font-size: 1.6rem;
}

.tiptap h2 {
	font-size: 1.4rem;
}

.tiptap h3 {
	font-size: 1.2rem;
}

.tiptap h4,
.tiptap h5,
.tiptap h6 {
	font-size: 1rem;
}

.tiptap code {
	@apply bg-card;
	font-family: "JetBrainsMono", monospace;
	border-radius: 0.4rem;
	color: var(--black);
	font-size: 0.85rem;
	padding: 0.25em 0.3em;
}

.tiptap pre {
	@apply bg-card;
	border-radius: 0.5rem;
	color: var(--white);
	font-family: "JetBrainsMono", monospace;
	margin: 1.5rem 0;
	padding: 0.75rem 1rem;
}

.tiptap pre code {
	@apply bg-card;
	font-family: "JetBrainsMono", monospace;
	color: inherit;
	font-size: 0.8rem;
	padding: 0;
}

.tiptap mark {
	background-color: #faf594;
	border-radius: 0.4rem;
	box-decoration-break: clone;
	padding: 0.1rem 0.3rem;
}

.tiptap blockquote {
	border-left: 3px solid var(--gray-3);
	margin: 1.5rem 0;
	padding-left: 1rem;
}

.tiptap hr {
	border: none;
	border-top: 1px solid var(--gray-2);
	margin: 2rem 0;
}

.tiptap p.is-editor-empty:first-child::before {
	@apply text-placeholder;
	content: attr(data-placeholder);
	float: left;
	height: 0;
	pointer-events: none;
}

.tiptap .editor-comment-highlight {
	@apply rounded-sm bg-accent/30;
}

.tiptap edit {
	@apply bg-accent/30;
}

.tiptap editTest {
	@apply bg-accent/30;
}

.tiptap .editor-addition-highlight {
	@apply rounded-sm bg-green-500/30;
}

.tiptap .editor-removal-highlight {
	@apply rounded-sm line-through bg-red-500/30;
}

.tiptap .mention {
	@apply cursor-pointer rounded-sm bg-accent/10 px-1 text-accent hover:bg-accent/20 hover:underline active:bg-accent/10;
}

.tiptap a {
	@apply underline text-accent hover:opacity-70 cursor-pointer active:opacity-50;
}

.tiptap .collaboration-cursor__caret {
	border-left: 1px solid #0d0d0d;
	border-right: 1px solid #0d0d0d;
	margin-left: -1px;
	margin-right: -1px;
	pointer-events: none;
	position: relative;
	word-break: normal;
}

/* Render the username above the caret */
.tiptap .collaboration-cursor__label {
	border-radius: 3px 3px 3px 0;
	color: #0d0d0d;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	left: -1px;
	line-height: normal;
	padding: 0.1rem 0.3rem;
	position: absolute;
	top: -1.4em;
	user-select: none;
	white-space: nowrap;
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}

	.px-inline-8 {
		padding-inline: 2rem 0;
	}
}

html {
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
	display: none;
}